<template>
  <div>
    <b-overlay
      id="overlay-background"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <div>
        <b-card-actions
          title="Filters"
          action-collapse
          collapsed
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Current Warehouse"
                label-for="current_warehouse"
              >
                <v-select
                  v-model="filters['current_warehouse']"
                  :options="warehouseOptions"
                  :reduce="option => option.id"
                  label="name"
                  placeholder="Select Warehouse"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Orders Created At"
                label-for="v-ref-no"
              >
                <div class="position-relative">
                  <flat-pickr
                    id="v-ref-no"
                    v-model="filters['orders.created_at']"
                    :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                    class="form-control"
                    placeholder="Search"
                  />
                  <div
                    v-if="filters['orders.created_at']"
                    class="flatpickr-cancel"
                    @click="clearDate('orders.created_at')"
                  >
                    <feather-icon icon="XIcon" />
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Orders Dispatched At"
                label-for="dispatched-date"
              >
                <div class="position-relative">
                  <flat-pickr
                    id="dispatched-date"
                    v-model="filters['statuses.created_at']"
                    :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                    class="form-control"
                    placeholder="Search"
                  />
                  <div
                    v-if="filters['statuses.created_at']"
                    class="flatpickr-cancel"
                    @click="clearDate('statuses.created_at')"
                  >
                    <feather-icon icon="XIcon" />
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Apply and Clear Filters -->
          <b-row class="mt-1">
            <b-col>
              <filter-buttons
                :filter-data="filters"
                :apply-filters="applyFilters"
                :clear-filters="clearFilters"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </div>
      <div>
        <b-card
          no-body
        >
          <div
            class="m-2"
          >
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <ReportTableButtons
                  :items="items"
                  :json_fieldz="json_fields"
                  :name="`WarehouseStatusCount - Page ${page}.xls`"
                  :bulkname="`WarehouseStatusCount.xls`"
                  :fetch="fetchDataForWarehouseStatusCountNoPagination"
                  @refresh="onClickRefresh"
                />
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(is_agent)="data">
              <b-badge :variant="`${data.value === 1 ? 'light-success' : 'light-primary'}`">
                {{ data.value === 1 ? 'Agent' : 'Branch' }}
              </b-badge>
            </template>
            <template #cell(order_count)="data">
              <div class="pr-3">
                {{ __numberWithCommas(data.value) }}
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-sm-start"
              >
                <label class="mr-1">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                md="9"
                class="d-flex align-items-center justify-content-sm-end"
              >
                <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :total-rows="(meta.total)? meta.total : 0"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BOverlay,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ReportRepository = RepositoryFactory.get('report')
const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    flatPickr,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BOverlay,
    BBadge,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      form: {},
      filters: {},
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 200],
      totalRows: 1,
      page: 1,
      meta: {},
      sortBy: '',
      sortDesc: false,
      dataLoading: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      warehouseOptions: [],
      fields: [
        {
          key: 'warehouse_name', label: 'Warehouse Name', thClass: 'text-left', tdClass: 'text-left',
        },
        {
          key: 'warehouse_ref_no', label: 'Ref No', thClass: 'text-left', tdClass: 'text-left',
        },
        {
          key: 'is_agent', label: 'Type', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'order_count', label: 'Order Count', thClass: 'text-right pr-5 mr-5', tdClass: 'text-right',
        },
      ],
      json_fields: {
        'Warehouse Name': {
          field: 'warehouse_name',
          callback: value => `"${value}"`,
        },
        'Ref No': 'warehouse_ref_no',
        'Warehouse Type': {
          field: 'is_agent',
          callback: value => (value === 1 ? 'Agent' : 'Branch'),
        },
        'Order Count': 'order_count',
      },
      items: [
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForWarehouseStatusCount()
      },
    },
    deep: true,
    page() {
      this.fetchDataForWarehouseStatusCount()
    },
    perPage() {
      this.fetchDataForWarehouseStatusCount()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getWarehouseList()
    this.fetchDataForWarehouseStatusCount()
  },
  methods: {
    async fetchDataForWarehouseStatusCount() {
      this.dataLoading = true
      this.items = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await ReportRepository.getWarehouseStatusCountReport(this.page, this.filterQuery, this.perPage))
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchDataForWarehouseStatusCountNoPagination() {
      try {
        const { data } = (await ReportRepository.getWarehouseStatusCountReportNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async getWarehouseList() {
      try {
        this.warehouseOptions = localStorage.getItem('warehouse_list') ? JSON.parse(localStorage.getItem('warehouse_list')) : (await ResourceRepository.getWarehouseListForDropDown()).data.data
        if (!localStorage.getItem('warehouse_list')) localStorage.setItem('warehouse_list', JSON.stringify(this.warehouseOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    handleChangePage(page) {
      this.page = page
      this.fetchDataForWarehouseStatusCount()
    },
    applyFilters() {
      this.fetchDataForWarehouseStatusCount()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchDataForWarehouseStatusCount()
      })
    },
    onClickRefresh() {
      this.fetchDataForWarehouseStatusCount()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../@core/scss/vue/libs/vue-flatpicker';
</style>
